@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@500;600&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 1rem 1rem;
  /* font-weight: 600; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

::-webkit-scrollbar {
  width: 0.6rem;
  background-color: #CAEEC2;
}
::-webkit-scrollbar-thumb {
  background-color: #066412;
  border-radius: 5px;
}
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}
